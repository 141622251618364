import React from "react";

import "../../static/styles/styles.css";
import HomeJSON from "../../content/HomeLinks.json";

import BackgroundHero from "../components/common/BackgroundHero";
import SEO from "../components/SEO";
import SplashLinks from "../components/SplashLinks";

const IndexPage = () => (
  <div>
    <SEO />
    <BackgroundHero imgAlt="SF Shores Image" />
    <div className="absolute w-full top-4/10 lg:top-9/20">
      <h1 className="flex justify-center items-center h-full bg-beige my-2 mx-0 p-3 text-black opacity-50 text-2xl lg:text-3xl">
        {HomeJSON.header}
      </h1>
      <SplashLinks linksCollection={HomeJSON.mainLinks} />
    </div>
  </div>
);

export default IndexPage;
