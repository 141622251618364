import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

interface BackgroundHeroProps {
  imgAlt: string;
}

const BackgroundHero = ({ imgAlt }: BackgroundHeroProps) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/sf-shores-progressive-1800.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 70
            breakpoints: [800, 1200, 1800]
            layout: FULL_WIDTH
          )
        }
      }
    }
  `);

  return (
    <div className="bg-hero-main bg-cover bg-center bg-no-repeat bg-black w-full h-full fixed">
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        className="hidden"
        alt={imgAlt}
      />
    </div>
  );
};

export default BackgroundHero;
