import React from "react";
import Link from "./common/Link";

import { LinkData } from "../models/LinkData";

interface SplashLinksProps {
  linksCollection: LinkData[];
}

const SplashLinks = ({ linksCollection }: SplashLinksProps) => {
  return (
    <div className="box-border bg-beige flex opacity-50 mx-auto px-0 lg:px-12">
      {linksCollection.map((linkItem, index) => {
        const { imgSrc, imgAlt, href } = linkItem;
        return (
          <div
            key={`${index}-${imgSrc}`}
            className="flex-1 justify-center p-5 lg:p-3"
          >
            <Link to={href}>
              <img
                src={imgSrc}
                className="hover:filter-dropshadow-black align-middle mx-auto w-4 h-4 lg:w-8 lg:h-8 "
                alt={imgAlt}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SplashLinks;
